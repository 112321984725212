import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', description = '', meta, cover, ...other }, ref) => (
   <>
      <Helmet>
         <title>{`${title}`}</title>

         <meta property="og:type" content="website" />
         <meta property="og:title" content={title} />
         <meta property="og:description" content={description} />
         <meta property="og:image" content={cover} />

         {meta}
      </Helmet>

      <Box ref={ref} {...other}>
         {children}
      </Box>
   </>
));

Page.propTypes = {
   children: PropTypes.node.isRequired,
   title: PropTypes.string,
   description: PropTypes.string,
   meta: PropTypes.node,
   cover: PropTypes.string,
};

export default Page;
