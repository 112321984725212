import { useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Link, Stack, Button, Tooltip, IconButton, Typography } from '@mui/material';
// redux
import { dispatch, useDispatch, useSelector } from '../redux/store';
import { getSocialMedias } from '../redux/slices/socialMedias';
//
import Iconify from './Iconify';
import { SkeletonKanbanColumn } from './skeleton';

// ----------------------------------------------------------------------

SocialsButton.propTypes = {
   initialColor: PropTypes.bool,
   links: PropTypes.objectOf(PropTypes.string),
   simple: PropTypes.bool,
   sx: PropTypes.object,
};

export default function SocialsButton({ initialColor = false, simple = true, links = [], sx, ...other }) {
   const { socialMedias, hasLoaded } = useSelector((state) => state.socialMedias);

   useEffect(() => {
      if (!hasLoaded) {
         dispatch(getSocialMedias());
      }
   }, [dispatch]);

   useEffect(() => {}, [hasLoaded, socialMedias]);

   const SOCIALS = [
      {
         name: 'Facebook',
         icon: 'eva:facebook-fill',
         socialColor: '#1877F2',
         path: socialMedias.facebook ? socialMedias.facebook : '' || '',
      },
      {
         name: 'Instagram',
         icon: 'ant-design:instagram-filled',
         socialColor: '#E02D69',
         path: socialMedias.instagram ? socialMedias.instagram : '' || '',
      },
      {
         name: 'Twitter',
         icon: 'eva:twitter-fill',
         socialColor: '#007EBB',
         path: socialMedias.twitter ? socialMedias.twitter : '' || '',
      },
   ];

   let hasLink = false;
   // eslint-disable-next-line no-plusplus
   for (let index = 0; index < SOCIALS.length; index++) {
      const element = SOCIALS[index];
      if (element.path) {
         hasLink = true;
         break;
      }
   }

   if (hasLink)
      return (
         <Stack alignItems="center" p={5}>
            {!socialMedias && <SkeletonKanbanColumn />}

            <Typography>acompanhe nossas redes sociais</Typography>

            <Stack alignItems={'center'} flexWrap="wrap">
               <Stack direction="row">
                  {socialMedias &&
                     // eslint-disable-next-line array-callback-return, consistent-return
                     SOCIALS.map((social) => {
                        const { name, icon, path, socialColor } = social;

                        if (path) {
                           return simple ? (
                              <Link key={name} href={path}>
                                 <Tooltip title={name} placement="top">
                                    <IconButton
                                       color="inherit"
                                       sx={{
                                          ...(initialColor && {
                                             color: socialColor,
                                             '&:hover': {
                                                bgcolor: alpha(socialColor, 0.08),
                                             },
                                          }),
                                          ...sx,
                                       }}
                                       {...other}
                                    >
                                       <Iconify icon={icon} sx={{ width: 20, height: 20 }} />
                                    </IconButton>
                                 </Tooltip>
                              </Link>
                           ) : (
                              <Stack key={name}>
                                 <Button
                                    href={path}
                                    target="_blank"
                                    color="inherit"
                                    variant="outlined"
                                    size="small"
                                    startIcon={<Iconify icon={icon} />}
                                    sx={{
                                       m: 0.5,
                                       flexShrink: 0,
                                       ...(initialColor && {
                                          color: socialColor,
                                          borderColor: socialColor,
                                          '&:hover': {
                                             borderColor: socialColor,
                                             bgcolor: alpha(socialColor, 0.08),
                                          },
                                       }),
                                       ...sx,
                                    }}
                                    {...other}
                                 >
                                    {name}
                                 </Button>
                              </Stack>
                           );
                        }
                     })}
               </Stack>
            </Stack>
         </Stack>
      );
}
