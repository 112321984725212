// @mui
import { Stack, Button, Typography } from '@mui/material';
// hooks
// import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
// routes
// import { PATH_DOCS } from '../../../routes/paths';
// assets
import { SeoIllustration } from '../../../assets';
import { APP_VERSION } from '../../../config';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
   // const { user } = useAuth();

   const { translate } = useLocales();

   return (
      <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
         <SeoIllustration sx={{ width: 1, height: '50%' }} />

         <div>
            <Typography gutterBottom variant="subtitle1">
               Suporte
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
               {translate('contato@sorteamos.com.br')}
            </Typography>
         </div>

         <Button
            href={`https://api.whatsapp.com/send?phone=5519999969999&text=Ola`}
            target="_blank"
            rel="noopener"
            variant="contained"
         >
            {translate('Fale conosco')}
         </Button>

         <Stack mt={2}>
            <Typography variant="caption">{APP_VERSION}</Typography>
         </Stack>
      </Stack>
   );
}
