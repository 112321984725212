import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Switch, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
   name: PropTypes.string,
   disabled: PropTypes.bool,
   disableRipple: PropTypes.bool,
   defaultChecked: PropTypes.bool,
   color: PropTypes.string,
};

export default function RHFSwitch({ name, disabled, disableRipple, defaultChecked, color, ...other }) {
   const { control } = useFormContext();

   return (
      <FormControlLabel
         control={
            <Controller
               name={name}
               control={control}
               render={({ field }) => (
                  <Switch
                     {...field}
                     checked={field.value}
                     disabled={disabled}
                     defaultChecked={defaultChecked}
                     disableRipple={disableRipple}
                     color={color}
                  />
               )}
            />
         }
         {...other}
      />
   );
}
