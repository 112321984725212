import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
   isLoading: false,
   error: null,
   capitalizers: [],
   capitalizer: null,
   sortBy: null,
   filters: {

   },
};

const slice = createSlice({
   name: 'capitalizer',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      resetCapitalizer(state) {
         state.capitalizer = null;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      updateCapitalizer(state, action) {
         state.isLoading = false;
         state.capitalizer = action.payload;

         console.log('updateCapitalizer state', state.capitalizer);
      },

      updateCapitalizers(state, action) {
         state.isLoading = false;
         state.capitalizers = action.payload;
      },

      // GET PRODUCTS
      getCapitalizersSuccess(state, action) {
         state.isLoading = false;
         state.capitalizers = action.payload;
      },

      // GET PRODUCT
      getCapitalizerSuccess(state, action) {
         state.isLoading = false;
         state.capitalizer = action.payload;
      },

      //  SORT & FILTER PRODUCTS
      sortByCapitalizers(state, action) {
         state.sortBy = action.payload;
      },

      filterCapitalizers(state, action) {
         console.log('estou no filterCapitalizers', state, action);
      },
   },
});

// Reducer
export default slice.reducer;

// Actions
export const {
   sortByCapitalizers,
   filterCapitalizers,
   getCapitalizersSuccess,
   getCapitalizerSuccess,
   startLoading,
   hasError,
   updateCapitalizer,
   updateCapitalizers,
   resetCapitalizer,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCapitalizers() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/capitalizers');
         const capitalizers = response.data;

         dispatch(slice.actions.getCapitalizersSuccess(capitalizers));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function getCapitalizerByName(name) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get(`/api/capitalizer/${name}`);
         const capitalizer = response.data;
         console.log('capitalizer response', capitalizer)

         dispatch(slice.actions.getCapitalizerSuccess(capitalizer));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function setCapitalizer(capitalizer) {
   return async () => {
      dispatch(slice.actions.updateCapitalizer(capitalizer));
   };
}