import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

// ----------------------------------------------------------------------

LogoCapitalization.propTypes = {
   disabledLink: PropTypes.bool,
   sx: PropTypes.object,
};

export default function LogoCapitalization({ disabledLink = false, sx }) {
   // const theme = useTheme();

   // const PRIMARY_LIGHT = theme.palette.primary.light;

   // const PRIMARY_MAIN = theme.palette.primary.main;

   // const PRIMARY_DARK = theme.palette.primary.dark;

   // OR
   // -------------------------------------------------------
   const logo = (
      <Stack direction={'row'} justifyContent={'center'}>
         <Stack direction={'row'} alignItems={'center'}>
            <Box
               component="img"
               src="https://incs-bucket.s3.amazonaws.com/capemisa.png"
               sx={{ maxWidth: 130, cursor: 'pointer', margin: '10px 5px 20px !important', ...sx }}
            />
            <Box
               component="img"
               src="https://incs-bucket.s3.amazonaws.com/logo-fenapestalozzi-horizontal-minify-black.png"
               sx={{ maxWidth: 150, cursor: 'pointer', margin: '10px 5px 20px !important', ...sx }}
            />
         </Stack>
      </Stack>
   );

   //    const logo = (
   //       <Box sx={{ width: 40, height: 40, ...sx }}>
   //          {/* <svg
   //             version="1.0"
   //             xmlns="http://www.w3.org/2000/svg"
   //             width="120px"
   //             height="120px"
   //             viewBox="0 0 250.000000 250.000000"
   //             preserveAspectRatio="xMidYMid meet"
   //          > */}
   //          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 250.000000 250.000000">
   //             <defs>
   //                <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
   //                   <stop offset="0%" stopColor={PRIMARY_DARK} />
   //                   <stop offset="100%" stopColor={PRIMARY_MAIN} />
   //                </linearGradient>
   //                <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
   //                   <stop offset="0%" stopColor={PRIMARY_LIGHT} />
   //                   <stop offset="100%" stopColor={PRIMARY_MAIN} />
   //                </linearGradient>
   //                <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
   //                   <stop offset="0%" stopColor={PRIMARY_LIGHT} />
   //                   <stop offset="100%" stopColor={PRIMARY_MAIN} />
   //                </linearGradient>
   //             </defs>

   //             <g transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)" fill={PRIMARY_DARK} stroke="none">
   //                <path
   //                   d="M953 2360 c-122 -25 -222 -108 -263 -218 -31 -80 -26 -186 12 -262
   // 30 -59 68 -101 344 -372 136 -134 186 -178 205 -178 19 0 80 55 264 243 l240
   // 242 -2 215 -2 215 -33 29 c-57 49 -135 80 -215 84 -84 5 -139 -11 -208 -58
   // l-45 -31 -33 25 c-74 57 -179 83 -264 66z"
   //                />
   //                <path
   //                   fill="url(#BG1)"
   //                   d="M385 1827 c-64 -21 -97 -42 -141 -89 -113 -118 -132 -275 -49 -413
   // 19 -32 35 -61 35 -65 0 -4 -16 -33 -35 -65 -50 -84 -62 -151 -41 -245 38 -177
   // 177 -280 361 -268 112 7 143 30 387 277 l213 216 -3 79 c-3 63 -1 77 10 70 25
   // -15 -132 153 -289 308 -182 180 -213 199 -333 205 -44 2 -91 -3 -115 -10z"
   //                />
   //                <path
   //                   fill="url(#BG2)"
   //                   d="M1916 1824 c-27 -8 -61 -23 -75 -31 -14 -9 -137 -127 -273 -262 -195
   // -193 -248 -251 -248 -271 0 -20 55 -80 258 -282 294 -292 296 -293 442 -293
   // 73 0 92 4 137 28 54 28 114 81 134 118 8 15 8 19 0 15 -8 -5 -11 45 -11 174 0
   // 134 3 179 12 174 14 -9 2 13 -23 42 -19 20 -18 21 6 54 27 37 30 45 15 35 -7
   // -4 -10 57 -10 175 0 129 3 179 11 174 21 -13 -11 35 -48 72 -78 79 -220 112
   // -327 78z"
   //                />
   //                <path
   //                   fill="url(#BG3)"
   //                   d="M1016 983 c-255 -249 -300 -301 -327 -373 -29 -77 -23 -186 15 -263
   // 35 -73 119 -150 187 -172 106 -36 264 -9 335 57 22 20 22 20 64 -11 65 -47
   // 130 -64 219 -59 90 5 151 31 207 86 l37 37 -2 203 c-1 164 1 202 11 196 34
   // -21 -71 91 -269 289 -158 156 -225 217 -242 217 -16 0 -83 -59 -235 -207z"
   //                />
   //             </g>
   //          </svg>

   //          {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
   //             <defs>
   //                <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
   //                   <stop offset="0%" stopColor={PRIMARY_DARK} />
   //                   <stop offset="100%" stopColor={PRIMARY_MAIN} />
   //                </linearGradient>
   //                <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
   //                   <stop offset="0%" stopColor={PRIMARY_LIGHT} />
   //                   <stop offset="100%" stopColor={PRIMARY_MAIN} />
   //                </linearGradient>
   //                <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
   //                   <stop offset="0%" stopColor={PRIMARY_LIGHT} />
   //                   <stop offset="100%" stopColor={PRIMARY_MAIN} />
   //                </linearGradient>
   //             </defs>

   //             <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
   //                <path
   //                   fill="url(#BG1)"
   //                   d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
   //                />
   //                <path
   //                   fill="url(#BG2)"
   //                   d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
   //                />
   //                <path
   //                   fill="url(#BG3)"
   //                   d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
   //                />
   //             </g>
   //          </svg> */}
   //       </Box>
   //    );

   if (disabledLink) {
      return <>{logo}</>;
   }

   return logo;
   // return <RouterLink to="/">{logo}</RouterLink>;
}
