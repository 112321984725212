import axios from 'axios';
// config
import { HOST_API } from '../config';
import { setSession } from './jwt';
import { PATH_PAGE } from '../routes/paths';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
   baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
   (response) => response,
   async (error) => {
      if (error.response) {
         if (error.response.status === 401) {
            setSession(null);
            window.location.reload();
         }
         if (error.response.status === 403) {
            window.location.href = PATH_PAGE.page403;
         }
      }
      return Promise.reject((error.response && error.response.data) || 'Algo deu errado com a solicitação!')
   }
);

export default axiosInstance;
