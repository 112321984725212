import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
   Box,
   List,
   Link,
   Drawer,
   Collapse,
   ListItemText,
   ListItemIcon,
   ListItemButton,
   Stack,
   Container,
   Divider,
   Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// config
import { NAVBAR } from '../../config';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { IconButtonAnimate } from '../../components/animate';
import { NavSectionVertical } from '../../components/nav-section';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
   ...theme.typography.h6,
   // textTransform: 'capitalize',
   height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
   color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
   isOffset: PropTypes.bool,
   isHome: PropTypes.bool,
   navConfig: PropTypes.array,
};

export default function MenuMobile({ isOffset, isHome, navConfig }) {
   const { pathname } = useLocation();

   const theme = useTheme();

   const [open, setOpen] = useState(false);

   const [drawerOpen, setDrawerOpen] = useState(false);

   const { user } = useAuth();

   useEffect(() => {
      if (drawerOpen) {
         handleDrawerClose();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pathname]);

   const handleOpen = () => {
      setOpen(!open);
   };

   const handleDrawerOpen = () => {
      setDrawerOpen(true);
   };

   const handleDrawerClose = () => {
      setDrawerOpen(false);
   };

   return (
      <>
         <IconButtonAnimate
            onClick={handleDrawerOpen}
            sx={{
               ml: 1,
               ...(isHome && { color: 'common.white' }),
               // ...(isOffset && { color: 'text.primary' }),
            }}
         >
            <Iconify icon={'eva:menu-2-fill'} />
         </IconButtonAnimate>

         <Drawer
            anchor="top"
            open={drawerOpen}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }}
            PaperProps={{
               sx: { pb: 5, width: '100%', height: '100%', bgcolor: '#000' },
            }}
         >
            <Container sx={{ maxWidth: '700px !important' }}>
               <Scrollbar>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                     <Logo sx={{ mx: 2.5, my: 3 }} />
                     <Iconify
                        icon={'material-symbols:close'}
                        width={36}
                        height={36}
                        onClick={handleDrawerClose}
                        cursor={'pointer'}
                        sx={{ mx: 2.5, my: 3 }}
                     />
                  </Stack>
                  <Divider />
                  <List disablePadding>
                     {navConfig.map((link) => (
                        <Stack key={link.title}>
                           <MenuMobileItem item={link} isOpen={open} onOpen={handleOpen} />
                           <Divider />
                        </Stack>
                     ))}
                  </List>
                  <LoadingButton
                     fullWidth
                     disabled={open}
                     size="large"
                     variant="contained"
                     onClick={() => setOpen(true)}
                     loading={false}
                     // sx={{ whiteSpace: 'nowrap' }}
                     sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                     <Iconify icon={'material-symbols:login'} width={18} height={18} />
                     <Typography ml={1} variant="button">
                        {user ? 'Sair' : 'Entrar'}
                     </Typography>
                  </LoadingButton>
               </Scrollbar>
            </Container>
         </Drawer>
      </>
   );
}

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
   isOpen: PropTypes.bool,
   item: PropTypes.shape({
      children: PropTypes.array,
      icon: PropTypes.any,
      path: PropTypes.string,
      title: PropTypes.string,
   }),
   onOpen: PropTypes.func,
};

function MenuMobileItem({ item, isOpen, onOpen }) {
   const { title, path, icon, children } = item;

   if (children) {
      return (
         <>
            <ListItemStyle onClick={onOpen}>
               <ListItemIcon>{icon}</ListItemIcon>
               <ListItemText disableTypography primary={title} />
               <Iconify icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ ml: 1 }} />
            </ListItemStyle>

            <Collapse in={isOpen} unmountOnExit>
               <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                  <NavSectionVertical
                     navConfig={children}
                     sx={{
                        '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                           height: 200,
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                           bgcolor: 'background.neutral',
                           backgroundRepeat: 'no-repeat',
                           backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                           '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
                        },
                     }}
                  />
               </Box>
            </Collapse>
         </>
      );
   }

   if (title === 'Documentation') {
      return (
         <Link href={path} target="_blank" rel="noopener" underline="none">
            <ListItemStyle>
               <ListItemIcon>{icon}</ListItemIcon>
               <ListItemText disableTypography primary={title} />
            </ListItemStyle>
         </Link>
      );
   }

   return (
      <ListItemStyle
         to={path}
         component={RouterLink}
         end={path === '/'}
         sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            '&.active': {
               color: 'primary.main',
               fontWeight: 'fontWeightMedium',
               bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
         }}
      >
         <ListItemIcon>{icon}</ListItemIcon>
         <ListItemText disableTypography primary={title} sx={{ fontSize: '14px' }} />
      </ListItemStyle>
   );
}
