import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

const initialState = {
   isLoading: false,
   error: null,
   settings: null,
};

const slice = createSlice({
   name: 'settings',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getSettingsSuccess(state, action) {
         state.isLoading = false;
         state.settings = action.payload;
      },

      setSettings(state, action) {
         state.isLoading = false;
         state.settings = action.payload;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getSettings() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/settings/all');
         dispatch(slice.actions.getSettingsSuccess(response.data));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

// ----------------------------------------------------------------------

export function setSettings(settings) {
   return async () => {
      dispatch(slice.actions.setSettings(settings));
   };
}
