// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
   width: 22,
   height: 22,
};

const menuConfigBackup = [
   {
      title: 'Home',
      icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
      path: '/',
   },
   {
      title: 'Pages',
      path: '/pages',
      icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
      children: [
         {
            subheader: 'Other',
            items: [
               { title: 'About us', path: PATH_PAGE.about },
               { title: 'Contact us', path: PATH_PAGE.contact },
               { title: 'FAQs', path: PATH_PAGE.faqs },
               { title: 'Order', path: PATH_PAGE.order },
               { title: 'Payment', path: PATH_PAGE.payment },
               { title: 'Maintenance', path: PATH_PAGE.maintenance },
               { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
            ],
         },
         {
            subheader: 'Authentication',
            items: [
               { title: 'Login', path: PATH_AUTH.loginUnprotected },
               { title: 'Register', path: PATH_AUTH.registerUnprotected },
               { title: 'Reset password', path: PATH_AUTH.resetPassword },
               { title: 'Verify code', path: PATH_AUTH.verify },
            ],
         },
         {
            subheader: 'Error',
            items: [
               { title: 'Page 403', path: PATH_PAGE.page403 },
               { title: 'Page 404', path: PATH_PAGE.page404 },
               { title: 'Page 500', path: PATH_PAGE.page500 },
            ],
         },
         {
            subheader: 'Dashboard',
            items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
         },
      ],
   },
   {
      title: 'Documentation',
      icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
      path: PATH_DOCS,
   },
];

const menuConfig = [
   {
      title: 'Início',
      icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
      path: '/',
   },
   {
      title: 'Campanhas',
      path: '/campanhas',
      icon: <Iconify icon={'gg:list'} {...ICON_SIZE} />,
   },
   {
      title: 'Comunicados',
      path: '/comunicados',
      icon: <Iconify icon={'iconoir:journal'} {...ICON_SIZE} />,
   },
   {
      title: 'Meus títulos',
      icon: <Iconify icon={'gg:list'} {...ICON_SIZE} />,
      path: '/meus-numeros',
   },
   {
      title: 'Ganhadores',
      icon: <Iconify icon={'bi:trophy'} {...ICON_SIZE} />,
      path: '/ganhadores',
   },
   {
      title: 'Termos de uso',
      icon: <Iconify icon={'carbon:policy'} {...ICON_SIZE} />,
      path: '/termos-de-uso',
   },
   {
      title: 'Entrar em contato',
      icon: <Iconify icon={'material-symbols:mail-outline'} {...ICON_SIZE} />,
      path: '/contato',
   },
];

export default menuConfig;
